













































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength, sameAs } from 'vuelidate/lib/validators'
import { getFieldError } from '@/core/tools/forms/get_field_error'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'
import ComboboxSelectedObserver from '@/components/Controls/ComboboxSelectedObserver.vue'
// import CompanyDetails from '../../Customers/СompanyDetails/СompanyDetails.vue';

@Component({
  mixins: [validationMixin],
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'fatalError', 'serverErrors',
      'form', 'formDirty',
    ]),
  },
  methods: {
    getFieldError,
  },
  validations() {
    const rules: any = {
      address: {},
      brand_id: {},
      merchant_id: {},
      name: {},
      mix_sum_for_mark: {},
      point: {},
      schedule: {},
      photo_upload_id: {},
      comment: {},
      is_online_shop: {},
      dropFiles: {},
    }

    return {
      form: rules,
    }
  },
  components: {
    TitleBar,
    ComboboxSelectedObserver,
  },
})
export default class OutletObserve extends Vue {
  public FILES_HOST = process.env.VUE_APP_UBIC_SERVER_ADDRESS

  get titleStack() {
    return [
      'Точки продаж', 'Просмотр',
    ]
  }

  // ---------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.$store.dispatch(`${STORE_KEY}/initState`, {
      id: this.$route.params.id,
      predefined: this.$route.query,
    })
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }
}
