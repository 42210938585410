import Vue from 'vue'
import { ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { merge, unset } from 'lodash'

interface OutletObserveState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  serverErrors: any;

  object: any;
  form: any;
}

const $state: OutletObserveState = {
  loading: false,
  loaded: false,
  fatalError: false,
  serverErrors: null,

  object: null,
  form: null,
}


const initState = cloneDeep($state)


const EMPTY_OBJECT = {
  address: null,
  brand_id: null,
  merchant_id: null,
  name: null,
  mix_sum_for_mark: null,
  point: null,
  schedule: null,
  photo_document: null,
  photo_upload_id: null,
  contacts: { contacts: [] },
  comment: null,
  is_online_shop: false,
}

const AVAILABLE_FIELDS = [
  'address', 'brand_id', 'merchant_id', 'name', 'point', 'schedule',
  'photo_upload_id', 'contacts', 'mix_sum_for_mark', 'comment', 'is_online_shop',
]

export const $actions: ActionTree<OutletObserveState, RootState> = {

  initState({ commit, state }, { id, predefined }) {
    return new Promise((resolve, reject) => {
      if (id) {
        const vm = (this as any)._vm

        vm.$http
          .get(`/admins/rest/v1/outlets/${id}`)
          .then((response: AxiosResponse) => {
            commit('SET_FORM_DATA', {
              object: response.data.outlet,
            })
            commit('LOADED_SUCCESS')

            resolve(state.object)
          })
          .catch((error: AxiosError) => {
            // tslint:disable-next-line:no-console
            console.error('Fatal error', error.response?.data)
            commit('LOADED_ERROR')
            reject(error.response?.data)
          })
      }
      else {
        return new Promise((resolve, reject) => {
          commit('GENERATE_FORM_DATA', predefined)
          commit('LOADED_SUCCESS')
          resolve(state.object)
        })
      }
    })
  },

  prepareNew({ commit, state }, predefined) {
    return new Promise((resolve, reject) => {
      commit('GENERATE_FORM_DATA', predefined)
      commit('LOADED_SUCCESS')
      resolve(null)
    })
  },

  changeFormValue({ commit }, { field, newValue }) {
    commit('CHANGE_VALUE', { field, newValue })
  },

  addAttachments({ state, commit }, newValue) {
    commit('CHANGE_VALUE', { field: 'photo_document', newValue })
    commit('CHANGE_VALUE', { field: 'photo_upload_id', newValue: newValue.id })
  },

  addContact({ state, commit }, { contact, type }) {
    commit('ADD_CONTACT', { contact, type })
  },

  saveObject({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      if (!state.form.contacts || !state.form.contacts.contacts) {
        state.form.contacts = { contacts: [] }
      }

      const cleanForm: any = {}
      for (const key of AVAILABLE_FIELDS) {
        if (state.form[key]) {
          cleanForm[key] = state.form[key]
        }
      }

      commit('START_LOADING')

      let request = null

      if (id) {
        request = vm.$http.patch(`/admins/rest/v1/outlets/${id}`, {
          outlet: cleanForm,
        })

      }
      else {
        request = vm.$http.post(`/admins/rest/v1/outlets`, {
          outlet: cleanForm,
        })
      }

      request
        .then((response: AxiosResponse) => {
          commit('LOADED_SUCCESS')

          resolve(response.data)
        })
        .catch((errorResponse: AxiosError) => {
          // eslint-disable-next-line
          console.error('Fatal error', errorResponse)

          if (errorResponse.response?.status === 500) {
            commit('LOADED_ERROR')
            reject()
          }
          else {
            const serverError = errorResponse.response?.data?.error

            commit('LOADED_SUCCESS')
            commit('SET_SERVER_ERROR', serverError)
            reject(serverError)
          }
        })
    })
  },
}

export const $mutations: MutationTree<OutletObserveState> = {
  RESET_STATE: (state) => {
    for (const key in initState) {
      (state as any)[key] = (initState as any)[key]
    }
  },
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_SERVER_ERROR: (state, errors) => {
    state.serverErrors = errors
  },
  ADD_CONTACT: (state, { contact, type }) => {
    console.log(state.form)
    if (!state.form.contacts || !state.form.contacts.contacts) {
      state.form.contacts = { contacts: [] }
    }
    state.form.contacts.contacts.push({
      value: contact,
      type,
    })
  },

  SET_FORM_DATA(state, { object }) {
    state.object = object

    const formData = cloneDeep(state.object)

    state.form = {
      ...formData,
    }
  },

  GENERATE_FORM_DATA(state, predefined) {
    state.form = {
      ...cloneDeep(EMPTY_OBJECT),
      ...predefined,
    }
  },

  CHANGE_VALUE(state: OutletObserveState, { field, newValue }) {
    state.form[field] = newValue
  },

  ON_FORM_SAVE(state, { object }) {
    state.object = object

    const formData = cloneDeep(object)

    state.form = {
      ...formData,
    }
  },

}

const $getters = {}

export const STORE_KEY = '$_outlet_observe'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
